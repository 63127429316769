'use strict';

angular.module('enervexSalesappApp').controller('CompanyCtrl', function($scope, $stateParams, $state, Company, Account, User, Contact, State, JobService, Country) {
	$scope.accountId = $stateParams.accountId;
	$scope.companyId = $stateParams.companyId;
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	Contact.query({
		accountId: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.contacts = res;
	});
	JobService.getStates().$promise.then(function(states){
		$scope.states = states;
	});
	Country.query({
		limit:300
	}).$promise.then(function(countries){
		$scope.countries = countries;
	});

	
	function getCompany() {
		Company.get({
			accountId: $stateParams.accountId,
			id: $stateParams.companyId
		}).$promise.then(function(companyInformation) {
			$scope.companyInformation = companyInformation;
		});
	}
	if ($stateParams.companyId != 'new'){
		getCompany();
	}
	$scope.updateCompany = function(companyInformation) {
		Company.update({
			id: $stateParams.companyId,
			accountId: $stateParams.accountId
		}, companyInformation).$promise.then(function() {
			$state.go('companies', {
				accountId: $scope.accountId,
			}, {
				inherit: false,
				reload: true
			});
		});
	}
	$scope.addCompany = function(companyInformation) {
		Company.save({
			accountId: $stateParams.accountId
		}, companyInformation).$promise.then(function(res){
			$state.go('companies', {
				accountId: $scope.accountId,
			}, {
				inherit: false,
				reload: true
			});
		});
	}
});
